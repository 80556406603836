<template>
  <div>
    <b-card no-body class="text-center mt-1">
      <template #header>
        <b-container fluid class="w-100">
          <b-row>
            <b-col cols="8" class="text-left">
              <h4 class="mb-0">
                Support Lists
              </h4>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <b-card-body class="text-left mt-1 ml-2 mr-2 mb-2">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Nama:</label>
              <b-form-input
                v-model="selectedNamaLengkap"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @keyup.enter="onEnterClick"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Konten:</label>
              <b-form-input
                v-model="selectedKonten"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @keyup.enter="onEnterClick"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2 align-items-end">
          <b-col
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="selectedPerPage"
              :options="optionsPerPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterSelected"
            />
            <label>(Found {{ pagination.totalRows }} records)</label>
          </b-col>
          <b-col md="4">
            <label>Sort by</label>
            <v-select
              v-model="selectedSortBy"
              label="nama"
              :options="optionsSortBy"
              :clearable="false"
              @input="filterSelected"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              responsive
              :items="daftarUser"
              :fields="fields"
              :busy="isBusy"
              class="mt-2"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle" />
                  <strong> Loading...</strong>
                </div>
              </template>
              <template #cell(id)="data">
                {{ data.value }}
              </template>
              <template #cell(konten)="{ item }">
                <b-link
                  :to="{
                    name: 'support-detil',
                    params: { id: item.id },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  <span v-html="item.konten" />
                </b-link>
              </template>
              <template #cell(action)="data">
                <div class="text-nowrap text-center">
                  <!-- Dropdown -->
                  <b-dropdown variant="link" toggle-class="p-0" no-caret>
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item :to="{
                      name: 'support-detil',
                      params: { id: data.item.id },
                    }"
                    >
                      <feather-icon icon="AlignCenterIcon" />
                      <span class="align-middle ml-50">Detail</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-pagination-nav
              v-if="showPagination"
              v-model="selectedPageNum"
              :link-gen="linkGen"
              :number-of-pages="pagination.numberOfPages"
              use-router
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  VBModal,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BContainer,
  BTable,
  BLink,
  BPaginationNav,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import moment from 'moment'
import { api } from '@/hap-sia/setup'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BContainer,
    BTable,
    BLink,
    BPaginationNav,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      optionsPerPage: [1, 3, 10, 25, 50, 100],
      selectedPerPage: 10,
      optionsSortBy: [
        {
          nama: 'Last Created',
          query: 'created_at|desc',
          id: 1,
        },
        {
          nama: 'Last Updated',
          query: 'updated_at|desc',
          id: 2,
        },
      ],
      selectedSortBy: {
        nama: 'Last Created',
        query: 'created_at|desc',
        id: 1,
      },
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'users[0].name', label: 'Nama' },
        { key: 'inbox_topik[0].nama', label: 'Topik' },
        { key: 'konten', label: 'Konten' },
        {
          key: 'action',
          label: 'Action',
          tdClass: 'custom-cell-action',
        },
      ],
      daftarUser: [
        {
          id: '123',
          nama_lengkap: 'Himawan Anindya Putra',
          email: 'saya@saya.com',
        },
      ],
      isBusy: false,
      selectedPageNum: 1,
      showPagination: false,
      pagination: {
        numberOfPages: 1,
        totalRows: 0,
        rowsPerPage: 0,
      },
      selectedNamaLengkap: null,
      selectedNia: null,
      selectedKonten: null,
      currentQuery: null,
      currentQueryParam: null,
      user_id: null,
      role: null,
    }
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.role
    this.user_id = userData.id
    const queryParams = this.processQueryString(this.$route.query)
    this.selectedPerPage = this.$route.query.limit
      ? this.$route.query.limit
      : 10

    // const generatedQueryString = this.buildQueryString(queryParams)
    await this.executeBackend(queryParams)
    this.showPagination = true
  },
  async beforeRouteUpdate(to, from, next) {
    // console.log(to)
    const queryParams = this.processQueryString(to.query)
    // const generatedQueryString = this.buildQueryString(queryParams)
    this.executeBackend(queryParams)
    next()
  },
  methods: {
    linkGen(pageNum) {
      const queryParams = this.processQueryString(this.currentQuery)
      queryParams.page = pageNum
      const generatedQueryString = this.buildQueryString(queryParams)
      // console.log(generatedQueryString)

      return `${generatedQueryString}`
      // console.log(pageNum)
    },
    newQueryParams() {
      return {
        user_id: null,
        role: null,
        nama_lengkap: null,
        nia: null,
        konten: null,
        sort_by: null,
        limit: 10,
        offset: null,
        page: 1,
      }
    },
    processQueryString(queryString) {
      const queryParams = this.newQueryParams()
      queryParams.user_id = this.user_id
        ? this.user_id
        : null

      queryParams.role = this.role
        ? this.role
        : null

      const selectedNamaLengkap = this.selectedNamaLengkap
        ? this.selectedNamaLengkap
        : null
      queryParams.nama_lengkap = queryString.nama_lengkap
        ? queryString.nama_lengkap
        : selectedNamaLengkap

      const selectedKonten = this.selectedKonten ? this.selectedKonten : null
      queryParams.konten = queryString.konten ? queryString.konten : selectedKonten

      queryParams.sort_by = queryString.sort_by
        ? queryString.sort_by
        : this.selectedSortBy.query
      queryParams.limit = queryString.limit
        ? queryString.limit
        : this.selectedPerPage
      queryParams.offset = queryString.offset ? queryString.offset : 0
      queryParams.page = queryString.page ? queryString.page : 1

      return queryParams
    },
    buildQueryString(params) {
      const queryParams = params

      // disinilah kita merubah offset disesuaikan dengan param page
      const { page } = queryParams
      // hitung offset
      if (page) {
        if (page === '1') {
          queryParams.offset = 0
        } else {
          queryParams.offset = (page - 1) * queryParams.limit
        }
      }
      const keys = Object.keys(queryParams)
      let i = 0
      let currentQuery = ''
      keys.forEach(element => {
        if (
          queryParams[element] !== null &&
          queryParams[element] !== undefined
        ) {
          if (i === 0) {
            currentQuery += '?'
            i += 1
          } else {
            currentQuery += '&'
          }
          currentQuery += `${element}=${queryParams[element]}`
        }
      })
      return currentQuery
    },
    async executeBackend(queryParams) {
      const generatedQueryString = this.buildQueryString(queryParams)
      this.isBusy = true
      // console.log(`executing ${generatedQueryString}`)
      try {
        this.daftarUser.splice(0, this.daftarUser.length)
        const result = await api().get(`search/support${generatedQueryString}`)
        result.data.items.forEach(element => {
          this.daftarUser.push(element)
        })
        this.pagination.totalRows = result.data.total
        this.pagination.numberOfPages = Math.ceil(
          this.pagination.totalRows / queryParams.limit,
        )
        if (this.pagination.numberOfPages === 0) {
          this.pagination.numberOfPages = 1
        }
        this.selectedNamaLengkap = queryParams.nama_lengkap
        this.selectedNia = queryParams.nia
        this.selectedPageNum = queryParams.page
        this.selectedSortBy = this.optionsSortBy.find(
          element => element.query === queryParams.sort_by,
        )
      } catch (error) {
        alert(error)
      }
      this.isBusy = false
      this.currentQuery = generatedQueryString
      this.currentQueryParam = queryParams
    },
    async filterSelected() {
      // reset query
      const queryParams = this.processQueryString({})
      const generatedQueryString = this.buildQueryString(queryParams)
      this.$router.push({
        path: `${generatedQueryString}`,
      })
    },
    confirmDelete(index) {
      console.log(index)
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 3000,
        noAutoHide,
      })
    },
    onEnterClick() {
      this.filterSelected()
    },
    tambahPenggunaBaru() {
      this.$router.push({
        name: 'support-add',
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.custom-cell-date {
  min-width: 200px;
  /* color: green;
  background-color: #00ff88; */
}
.custom-cell-action {
  min-width: 140px;
  /* color: green;
  background-color: #00ff88; */
}
</style>
